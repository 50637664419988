.organization-report__container {
	.header {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.actions {
			display: flex;
			align-items: center;
		}
	}

	.rmp-table.show {
		top: 20px;
		right: 200px !important;
		left: initial !important;
	}

	.data-container {
		display: flex;
		flex: 1;
		padding: 16px 22px;
		background-color: #fff;
		border-radius: 24px;
		flex-direction: column;
	}

	.report-grid {
		display: grid;
		column-gap: 24px;
		row-gap: 24px;
		column-count: 4;
		grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
	}

	.report-features-container {
		.report-feature {
			.title {
				color: #2C3589;
				font-size: 14px;
				font-weight: 500;
			}

			.amount {
				color: #566FFF;
				font-size: 24px;
				font-weight: 500;
				margin-top: 8px;
			}

			.delta {
				font-size: 12px;
				color: #5A5D77;
				font-weight: 500;
				text-align: left;
				margin-top: 8px;
			}
		}
	}

	.state-report {
		margin-top: 24px;

		.state-changes {
			.title {
				color: #2C3589;
				font-size: 14px;
				font-weight: 500;
			}

			.state-container {
				margin-top: 33px;
				display: flex;
				flex-direction: column;

				.state {
					display: flex;
					margin-bottom: 19px;

					.name {
						flex: 1;
						font-weight: 500;
						color: #5A5D77;
						font-size: 14px;
					}

					.value {
						width: 100%;
						max-width: 45px;
						font-weight: 500;
						color: #566FFF;
						font-size: 14px;
					}
				}
			}
		}

		.state-timeline {
			grid-column: 1 / 3;
			max-height: 260px;

			.title {
				color: #2C3589;
				font-size: 18px;
				font-weight: 500;
				line-height: 22px;
			}

			.data-summary {
				width: 240px;

				.state {
					display: flex;
					margin-bottom: 19px;

					&:last-of-type {
						margin-bottom: 0px;
					}

					.name {
						flex: 1;
						font-weight: 500;
						color: #5A5D77;
						font-size: 14px;
					}

					.value {
						width: 100%;
						max-width: 80px;
						font-weight: 500;
						color: #566FFF;
						font-size: 14px;
						text-align: right;
						padding-right: 35px;
					}
				}
			}

			.timeline-container {
				display: flex;
				flex: 1;
				align-items: center;

				.graph {
					flex: 1;
				}
			}
		}
	}

	.employees-table {
		margin-top: 24px;

		.profile-picture {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}
	}
}