.loader {
    position: absolute;
    color: #2C3589;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
}
.imageLoader{
    margin: 30px auto;
    display: block !important;
}
.imageLoaderContainer p {
    text-align: center;
    display: block;
}

.simpleLoaderContainer p {
    text-align: center;
    display: block;
}

.simpleLoader{
    margin: 30px auto;
    display: block !important;
}