@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}
*:focus {
    outline: none;
}
textarea,
select {
  cursor: pointer;
  text-align: justify !important;
  font-weight: 200;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
input {
  cursor: pointer;
}
select {
  font-size: 14px;
  white-space: nowrap;
  display: block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 120px;
}
.MuiList-padding{
  padding:0 !important;
}
.disabled {
  opacity: 0.3;
}
.enabled {
  opacity: 1;
}
html {
  overflow-y: hidden;
}
h1 {
  font-size: 24px;
  font-weight: 700;
  color: #323643;
}
h2 {
  font-size: 14px;
  color: #535f69;
  font-weight: 400;
}
h7 {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
h7 {
  margin-bottom: 0;
}
h4 {
  font-size: 9px;
  margin-top: 4px;
  font-weight: 100;
  color: #323643;
}
h5 {
  font-size: 14px;
  font-weight: 200;
  color: #535f69;
}
h6 {
  font-size: 16px;
  margin: 12px 0px;
}
.flexAlignStart {
  align-items: start !important;
}
p {
  word-wrap: break-word;
  font-size: 14px;
  text-align: justify;
  font-weight: 200;
  color: #535f69;
  word-break: normal;
}
.thin {
  font-weight: 100;
}

.dottedBorder {
  border-bottom: 1px dashed #dddddd;
}

.flexed {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexedColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
}

.onlyFlex {
  display: flex;
}

.flexWrapped{
  flex-wrap: wrap;
}
.flexedNotJustified {
  align-items: center;
  display: flex;
}
.marginRight {
  margin-right: 24px !important;
}
.padded {
  padding: 24px;
}
.paddedBottom {
  padding-bottom: 24px;
}
.paddedVertically {
  padding: 24px 0;
}
.paddedHorizontally {
  padding: 0 24px;
}
.noPadding {
  padding: 0;
}
.paddedRightOnDesktop {
  padding-right: 24px;
}
.paddedTopOnMobile {
  padding-top: 0;
}
.paddedExceptRight {
  padding: 24px 0px 24px 24px;
}
.burgerMenu {
  display: none !important;
}
.desktopNav {
  height: 90%;
  padding: 0px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rotate {
  transform: rotate(180deg);
}
.hidden {
  opacity: 0;
}

/* Break point towards mobile - we are desktop first and adjust things when going smaller */
@media only screen and (max-width: 959px) {
  .modalContent {
    width: 80%;
  }
  .paddedRightOnDesktop {
    padding-right: 0;
  }
  .paddedTopOnMobile {
    padding-top: 24px;
  }
  .propertiesContainer {
    padding: 0;
    margin-top: 10%;
  }
  .burgerMenu {
    display: flex !important;
  }
  .centralControl {
    transform: scale(0.8);
    right: 5px;
    top: 2px;
  }
  .centralControl > button {
    box-shadow: none;
  }
}
.scrollableContainer {
  max-height: 200px;
  overflow-y: auto;
}
.noBorder {
  border-bottom: none !important;
}
.userContainer {
  position: absolute;
  bottom: 30px;
}


.generalInformation > .textBoxWithHeader {
  flex-grow: 0;
  display: block;
  width: 50%;
  height: 50px;
}

table {
  border-collapse: separate;
}
th {
  min-width: 33.33%;
}
tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.mobileAvatar img {
  max-width: 21px;
  max-height: 21px;
}

.mobileAvatar .userAvatar {
  border-radius: 0px;
  justify-content: initial;
}

.MuiInputBase-input {
  font-size: 14px !important;
}
.scaledX2 {
  transform: scale(2);
}

.textBoxWithHeader input {
  padding: 0 !important;
}
.MuiNativeSelect-icon, .MuiSelect-icon{
  display: none !important;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}